@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;

$m3-dark-theme: matx.define-theme((
  color: (
    theme-type: dark,
    primary: matx.$m3-violet-palette,
  )
));

$m3-light-theme: matx.define-theme((
  color: (
    primary: matx.$m3-violet-palette,
    )
));

.dark-theme {
  @include mat.core();
  @include mat.all-component-themes($m3-dark-theme);
  @include matx.color-variants-back-compat($m3-dark-theme);
}

.light-theme {
  @include mat.core();
  @include mat.all-component-themes($m3-light-theme);
  @include matx.color-variants-back-compat($m3-light-theme);
}
