/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;

html,
body {
    padding: 0px;
    margin: 0px;
    height: 100%;
}

body {
    background-color: #FFFCF9;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
}
